import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const resources = {
  "Recruitment & Interview Guides": [
    {
      name: "Banking at Michigan: IB Recruitment Guide",
      url: "https://www.bankingatmichigan.org/s/BAM_MASTERDECK_31.pdf",
    },
    {
      name: "Western Investment Club: IB Recruitment Guide",
      url:
        "https://static1.squarespace.com/static/5cf45640c775120001d4c3cc/t/5e0168693c0d24577665c71a/1577150573184/2019-2020+WIC+Finance+Recruiting+Guide.pdf",
    },
    {
      name: "NYU Stern's AKPsi Chapter: IB Recruitment Guide",
      url:
        "https://static1.squarespace.com/static/5b00e278a2772cd1d9f5d164/t/5b949b884fa51ad190326cea/1536465806276/Alpha-Kappa-Psi-Investment-Banking-Recruitment-Overview-vF.pdf",
    },
  ],
  "Supplemental Material": [
    {
      name: "Banking at Michigan: Email Guide",
      url:
        "https://www.bankingatmichigan.org/s/Email-Guide-for-IB-Recruitment-2.pdf",
    },
    {
      name: "Vampire Squid Podcast (Episodes 001-016)",
      url: "http://www.thevampiresquid.com/podcast-library/",
    },
    {
      name: "Paper LBO Guide",
      url:
        "http://www.streetofwalls.com/finance-training-courses/private-equity-training/paper-lbo-model-example/",
    },
  ],
  "Financial Modeling": [
    {
      name: "A Simple Model",
      url:
        "https://www.asimplemodel.com/topic/14/asm-quick-references/free-financial-model-downloads/",
    },
    {
      name: "Valuation University",
      url: "https://www.valuationuniversity.com/",
    },
  ],
  "Investment Banking Overview": [
    {
      name: "Mergers & Inquisitions: Investment Banking Blog",
      url: "https://www.mergersandinquisitions.com/investment-banking/",
    },
    {
      name: "Street of Walls: Investment Banking Blog",
      url: "http://www.streetofwalls.com/articles/investment-banking/",
    },
    {
      name: "Duke University: Investment Banking Demystified",
      url:
        "https://econ.duke.edu/sites/econ.duke.edu/files/file-attachments/Investment%20Banking%20Demystified.pdf",
    },
    {
      name: "Morgan Stanley: Quick Guide to Investment Banking",
      url:
        "http://www.morganstanley.com/content/dam/msdotcom/en/about/offices/pdf/ei-infograph.pdf",
    },
    {
      name: "Vault: Best Banking Firms",
      url: "http://www.vault.com/company-rankings/banking/",
    },
  ],
}

class Resources extends Component {
  render() {
    let content = []

    for (let k in resources) {
      content.push(<span className="lozenge lozenge-resources">{k}</span>)
      resources[k].forEach(r => {
        content.push(
          <div className="mb-8">
            <a
              className="resource-link"
              href={r.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {r.name}
            </a>
          </div>
        )
      })
      content.push(<div className="mb-16"></div>)
    }

    return (
      <Layout location={this.props.location} crumbLabel="Resources">
        <SEO
          title="Resources"
          description="Investment banking recruitment resources, including recruitment guides, technical decks and other supplemental material."
        />
        <div className="page page-saved">
          <div className="header-1 mb-8">Resources</div>
          <div className="content content-sources">
            A repository of resources to help you navigate the investment
            banking recruitment process.
          </div>
          {content}
        </div>
      </Layout>
    )
  }
}

export default Resources
